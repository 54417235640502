const globalIndex = ref<number>(0);

export const useModalIndex = function () {
  const currentIndex = ref(globalIndex.value);

  return {
    currentIndex,
    globalIndex,
  };
};

export const useModalAutoIndex = function () {
  const increase = function () {
    globalIndex.value++;
  };

  const decrease = function () {
    if (globalIndex.value === 0) return;

    globalIndex.value--;
  };

  increase();

  onBeforeUnmount(() => {
    decrease();
  });

  return {
    globalIndex,

    increase,
    decrease,
  };
};
