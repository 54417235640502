import type { KeyFilter, OnKeyStrokeOptions } from "@vueuse/core";

export const useKeyStroke = function (
  key: KeyFilter,
  handler: (event: KeyboardEvent) => void,
  options?: OnKeyStrokeOptions | undefined
) {
  const { currentIndex, globalIndex } = useModalIndex();

  const activeElement = useActiveElement();

  const whiteListedKeys = ["ArrowUp", "ArrowDown", "Escape", "Enter", "Backspace"];

  const decorator = function (handler: (event: KeyboardEvent) => void) {
    const wrapper = function (event: KeyboardEvent) {
      // Check if KBar is active
      // const isKBarActive = ["visible", "leaving"].includes(stateKBar.value.visibility);

      // Exit function if KBar is active
      // if (isKBarActive) return;

      // Exit function if modal layer is not active
      if (currentIndex.value !== globalIndex.value) return;

      // If input is currenty being focused on
      if (activeElement.value?.localName === "input") {
        if (!whiteListedKeys.includes(event.key)) return;
      }

      handler(event);
    };

    return wrapper;
  };

  return onKeyStroke(key, decorator(handler), options ?? {});
};
